import React from "react"
import styled from "styled-components"
import color from "../../style/color"
import Button from "../../shared/Button/Button"

interface ConfirmationComponentProps {
  firstName?: string
  lastName?: string
  practice?: string
  treatment?: string
  ctaUrl?: string
  dentistName?: string
  page?: string
  title?: string
  dob?: string
  address?: string
  phone?: string
  practiceName?: string
  practiceAddress?: string
  practicePhone?: string
  treatments?: any
  noTreatmentSelected?: boolean
  category?: string
}

const ConfirmationComponent = ({
  firstName,
  lastName,
  practice,
  treatment,
  ctaUrl,
  dentistName,
  page,
  title,
  dob,
  address,
  phone,
  practiceName,
  practiceAddress,
  practicePhone,
  treatments,
  noTreatmentSelected,
  category,
}: ConfirmationComponentProps) => {
  return (
    <Container>
      {page === "dentistPage" ? (
        <>
          {" "}
          <Text>
            <Heading>Hi {dentistName},</Heading>
            <ConfirmationText>
              Thank you for referring your patient to
              <span> Wytes</span>. A member of our team will be in contact soon.
            </ConfirmationText>
          </Text>
          <Flex>
            <Col>
              <SubHeading>Patient details</SubHeading>
              <hr />
              <P>Treatment: {category},</P>
              <P>
                {title} {firstName} {lastName},
              </P>

              <P>{phone}</P>
            </Col>
            <Gap />
            <Col>
              <SubHeading>Practice details</SubHeading>
              <hr />

              <P>{dentistName},</P>
              <P>{practicePhone},</P>
              <P>{practiceAddress},</P>
              <P>{dob}</P>
            </Col>
          </Flex>
        </>
      ) : page === "invisalign" ? (
        <Text>
          <Heading>
            Hi {firstName} {lastName},
          </Heading>
          <Light>Thank you for completing the Invisalign Assessment!</Light>
          <ConfirmationText>
            A member of our <span>{practice}</span> team will be in contact soon
            to discuss your enquiry.
          </ConfirmationText>
        </Text>
      ) : page === "patient" ? (
        <Text>
          <Heading>
            Hi {firstName} {lastName},
          </Heading>
          <Light>Thank you for getting in touch</Light>
          <ConfirmationText>
            {!noTreatmentSelected ? (
              <>
                A member of our <span>{practice}</span> team will be in contact
                soon to discuss your enquiry.
              </>
            ) : (
              <>
                {" "}
                A member of our <span>{practice}</span> team will be in contact
                soon to discuss your enquiry on {treatments}.
              </>
            )}
          </ConfirmationText>
        </Text>
      ) : page === "consultation" ? (
        <Text>
          <Heading>
            Hi {firstName} {lastName},
          </Heading>
          <Light>Thank you for getting in touch</Light>
          <ConfirmationText>
            A member of our <span>{practice}</span> team will be in contact soon
            to discuss your enquiry.
          </ConfirmationText>
        </Text>
      ) : (
        <Text>
          <Heading>
            Hi {firstName} {lastName},
          </Heading>
          <Light>Thank you for getting in touch</Light>
          <ConfirmationText>
            A member of our <span>{practice}</span> team will be in contact soon
            to discuss your enquiry on <span>{treatment}</span>.
          </ConfirmationText>
        </Text>
      )}
      <Button text="Continue" href={ctaUrl} formConfirmation />
    </Container>
  )
}

export default ConfirmationComponent

export const Container = styled.div`
  width: 100%;
  min-height: 100vh;
  text-align: center;
  background: ${color.brand.green};
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  @media (max-width: 767px) {
    width: 100%;
    padding: 5rem 25px;
  }
`
export const Text = styled.div`
  width: 100%;
  max-width: 75rem;
  margin: 0 auto;
`

export const Heading = styled.h2`
  text-align: center;
  word-break: break-all;
`
export const Light = styled.h2``
export const ConfirmationText = styled.div`
  font-size: 2.2rem;
  line-height: 3.2rem;
  margin: 4rem 0 5rem;
  & span {
    font-weight: bold;
  }
`
const Flex = styled.div`
  display: flex;
  width: 92%;
  max-width: 995px;
  margin: 0 auto 8rem;
  @media (max-width: 767px) {
    flex-direction: column;
    max-width: 100%;
    width: 100%;
    margin-bottom: 4rem;
  }
`
const Col = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
  flex: 1;
  & hr {
    border: none;
    height: 1px;
    background-color: white;
    margin-bottom: 1.7rem;
  }
`
const Gap = styled.div`
  width: 4.3rem;
  @media (max-width: 767px) {
  }
`

const SubHeading = styled.p`
  font-size: 2.2rem;
  line-height: 3.2rem;
  margin-bottom: 1.3rem;
`
const P = styled.p`
  font-weight: 300;
  font-size: 1.6rem;
  line-height: 2.6rem;
`
