import React, { useEffect, useState } from "react"
import InvisalignFormConfirmation from "../components/Form/Invisalign/InvisalignFormConfirmation"
import Layout from "../helper/layout"

const InvisalignFormConfirmationPage = () => {
  return (
    <Layout>
      <InvisalignFormConfirmation />
    </Layout>
  )
}

export default InvisalignFormConfirmationPage
