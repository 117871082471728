import React from "react"
import { getItem } from "../../../util/functions"
import ConfirmationComponent from "../ConfirmationComponent"

const InvisalignFormConfirmation = () => {
  return (
    <ConfirmationComponent
      firstName={getItem("firstName")}
      lastName={getItem("lastName")}
      practice={getItem("practice")}
      treatment={getItem("treatment")}
      ctaUrl={process.env.BASE}
      page="invisalign"
    />
  )
}

export default InvisalignFormConfirmation
